import { createContext, useContext, useMemo } from 'react';
import useFilter from './useFilter';

export const FilterContext = createContext();
export const useFilterContext = () => useContext(FilterContext);

const FilterProvider = ({ children }) => {
  const filter = useFilter();
  const context = useMemo(() => ({ filter }), [filter]);
  return (
    <FilterContext.Provider value={context}>{children}</FilterContext.Provider>
  );
};

export default FilterProvider;
