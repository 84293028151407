import { useCallback, useEffect, useState } from 'react';
import { Size } from '@/atoms/enums';
import { Label } from '@/atoms/typography';
import { InlineTextField } from '@/molecules/formElements';
import { TextMetricGroup } from '@/molecules/metrics';
import { RoleSummary } from '@/molecules/summaries';
import { OutlineCard } from '@/organisms/cards';
import {
  useIsSupporter,
  useIsTeamLead,
  useScenarioMetrics,
} from '@/shared/hooks';
import { usePerson } from '@/shared/hooks';
import { DispatchProvider } from '@/shared/providers';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { decimal } from '@pkg/utils/inputs';
import { format } from '@pkg/utils/numbers';
import config from '@/lib/config';
import { Property } from '@/lib/enums';
import { EntityMetric } from '@/lib/enums';

const EntityOverviewCard = ({ isEditable = false }) => {
  const isSupporter = useIsSupporter();
  const { scenario } = useScenarioContext();
  const [fte, setFte] = useState();
  const isTeamLead = useIsTeamLead(scenario?.entity);
  const { avatar, person } = usePerson(scenario?.entity);
  const metrics = useScenarioMetrics([
    EntityMetric.ACTIVITIES,
    EntityMetric.HOURS,
  ]);
  const hasFte = fte !== null && typeof fte !== 'undefined';

  const formatFTE = useCallback((value) => {
    return decimal.blur(value, { max: config.MAX_FTE });
  });

  const formatFTEDisplay = useCallback((value) => {
    return format(value, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 1,
    });
  });

  useEffect(() => {
    if (typeof scenario?.entity?.fte === 'undefined') {
      return;
    }

    setFte(formatFTEDisplay(scenario.entity.fte));
  }, [scenario?.entity?.fte]);

  return (
    scenario?.entity &&
    hasFte && (
      <OutlineCard>
        <RoleSummary
          entity={scenario?.entity}
          isSupporter={isSupporter}
          isTeamLead={isTeamLead}
          isScenario={scenario?.details?.isScenario}
          avatar={avatar}
          person={person}
          size={Size.SMALL}
        />
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <TextMetricGroup
              endDivider
              metricDirection="row"
              metrics={metrics}
              size={Size.MEDIUM}
            />
            <Box maxWidth="50px" ml={0.5}>
              <DispatchProvider
                uuid={scenario?.entity?.uuid}
                action="roles.update"
                isEditable={isEditable}
                name={Property.FTE}
              >
                <InlineTextField
                  uuid={scenario?.entity?.uuid}
                  valueFormatter={formatFTE}
                  displayFormatter={formatFTEDisplay}
                  initialValue={fte}
                  id={scenario?.entity?.uuid}
                  fullWidth={false}
                  inputProps={{
                    min: 0,
                    type: 'number',
                  }}
                  name={Property.FTE}
                  placeholder="1"
                  textAlign="center"
                  size={Size.SMALL}
                />
              </DispatchProvider>
            </Box>
            <Label size={Size.MEDIUM} overrideStyles={{ fontWeight: 500 }}>
              FTE
            </Label>
          </Stack>
        </Stack>
      </OutlineCard>
    )
  );
};

export default EntityOverviewCard;
