export default function comparsionFromRoute(route, levelType) {
  const [compare, uuid, designId, revisionId] = route;

  if (compare !== 'compare' || !uuid) {
    return;
  }

  return {
    designId: designId ?? 'main',
    level: {
      type: levelType,
      uuid,
    },
    revisionId: revisionId ?? 'latest',
  };
}
