import { brand, core } from '@/lib/theme/tokens/palettes';

const BaseColor = Object.freeze({
  font: {
    primary: '#4a535a',
    secondary: core.white,
    tertiary: '#364047',
    white: core.white,
  },
  background: {
    primary: core.white,
    secondary: '#fafafa',
    tertiary: brand._navy.main,
  },
  border: {
    primary: '#e1e8f4',
    secondary: brand.yellow.main,
    tertiary: '#a6afc0',
    grey: '#E0E6F0',
  },
});

export default BaseColor;
