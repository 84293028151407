import { useState, useEffect } from 'react';
import { calculateComparisonMetrics } from '@/shared/utils';
import { DesignEntity } from '@/lib/enums';

/**
 * Returns metrics comparing a primary scenario to a comparison scenario.
 *
 * @param {Object} primary
 * @param {Object} comparison
 * @return {Object}
 */
const useComparisonMetrics = (primary, comparison) => {
  const [metrics, setMetrics] = useState();
  const [activityMap, setActivityMap] = useState(new Map());

  useEffect(() => {
    if (
      !primary?.scenario?.relationships?.size ||
      !comparison?.scenario?.relationships?.size
    ) {
      return;
    }

    const metrics = calculateComparisonMetrics(
      primary?.scenario,
      comparison?.scenario
    );

    setMetrics(metrics);
    setActivityMap(
      new Map(comparison?.scenario?.relationships?.get(DesignEntity.ACTIVITY))
    );
  }, [
    primary?.scenario?.entity?.updated_at,
    comparison?.scenario?.entity?.updated_at,
  ]);

  return {
    metrics,
    activityMap,
  };
};

export default useComparisonMetrics;
