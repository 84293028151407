import * as Colors from '@/atoms/colors';
import { InsightsNavigation } from '@/organisms/navigation';
import { InsightsProvider, useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InsightsScope from './InsightsScope';

const InsightsLayout = () => {
  const { scope } = useViewModeContext();

  return (
    <InsightsProvider>
      <Box
        color={Colors.Base.font.primary}
        height="calc(100vh - 87px)"
        position="relative"
        sx={{
          backgroundColor: Colors.Base.background.secondary,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: 116,
            top: 16,
            width: 320,
          }}
        >
          <InsightsNavigation />
        </Box>
        <Stack
          direction="row"
          height="100%"
          justifyContent="flex-end"
          sx={{
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
          }}
        >
          <Box width="calc(100vw - 453px)" p={2} pb={14}>
            <InsightsScope scope={scope} />
          </Box>
        </Stack>
      </Box>
    </InsightsProvider>
  );
};

export default InsightsLayout;
