import { useRouter } from 'next/router';
import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { ToggleButton } from '@/atoms/buttons';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { Tooltip } from '@/molecules/modals';
import { Modal } from '@/molecules/modals';
import { DesignSelector } from '@/molecules/navigation';
import ModeSelector from '@/molecules/navigation/ModeSelector/ModeSelector';
import Filters from '@/organisms/filters';
import { AddScenarioForm } from '@/organisms/forms';
import { EventName, EventProperty } from '@/shared/enums';
import { FilterProvider } from '@/shared/providers';
import { useViewModeContext, useCanSwitchViewModes } from '@/shared/providers';
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AttachMoney from '@mui/icons-material/AttachMoney';
import EditOffIcon from '@mui/icons-material/EditOff';
import MoneyOff from '@mui/icons-material/MoneyOff';
import Stack from '@mui/material/Stack';
import { Analytics } from '@pkg/analytics';
import * as Auth from '@pkg/auth';
import { Routes } from '@pkg/utils';
import { DesignLevel } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';
import BulkEditModal from '@/components/BulkEdit/BulkEditModal';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import AuthenticatedToolbar from '@/components/DesignContainer/parts/Layout/Toolbars/Authenticated';

const SecondaryNavigationBar = ({ viewMode }) => {
  const isHistorical = useDesignStore((state) => state.isHistorical);
  const {
    selectViewMode,
    canAccessBudget,
    showBudget,
    updateBudgetVisibility,
    view,
  } = useViewModeContext();
  const canSwitchViewModes = useCanSwitchViewModes();
  const level = useDesignStore((state) => state.level);
  const me = Auth.useStore((state) => state.me);

  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);

  const handleWizard = () => {
    setBulkModalOpen(!bulkModalOpen);
  };

  const [addScenarioOpen, setAddScenarioOpen] = useState(false);
  const router = useRouter();

  const handleOpenAddScenario = () => {
    setAddScenarioOpen(true);
  };

  const handleSelectViewMode = (modeId) => {
    selectViewMode(modeId);
    Analytics.track(EventName.SWITCHED_VIEW_MODE, {
      [EventProperty.ENTRY]: 'Secondary navigation',
      [EventProperty.VIEW_MODE]: modeId,
    });
  };

  let canCreateScenario = true;

  if (me.is_role_only && level?.type !== DesignLevel.ROLE) {
    canCreateScenario = false;
  }

  /** Redirects to the new scenario or closes the modal.
   *
   * @param {Object} event
   * @param {Object} scenario
   */
  const handleCloseAddScenario = (event, scenario) => {
    if (!scenario) {
      setAddScenarioOpen(false);
      return;
    }

    const { entity, scenarioId, scope } = scenario;

    const path = Routes.build.designParts({
      designId: scenarioId,
      level: {
        type: scope,
        uuid: entity?.uuid ?? entity?.__uuid,
      },
      params: {
        view: viewMode,
      },
    });

    if (path) {
      router.push(path);
    }
  };

  return (
    <FilterProvider>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px={2}
        sx={{
          boxShadow: `0 1px 3px 0 ${brand.grey.tints[3]}, 0 1px 0 0 ${brand.grey.tints[1]}`,
          justifyContent: 'space-between',
        }}
      >
        <Stack alignItems="center" direction="row" py={1} spacing={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Paragraph size={Size.SMALL}>Design:</Paragraph>
            <DesignSelector viewMode={viewMode} />
          </Stack>
          {canSwitchViewModes && (
            <ModeSelector initial={view} onClick={selectViewMode} />
          )}
          <Filters />
          {isAuthenticated && (
            <>
              <div>
                <Button
                  label="Bulk Edit"
                  color="light-blue"
                  onClick={handleWizard}
                  startIcon={<EditOffIcon />}
                />
                {bulkModalOpen && (
                  <BulkEditModal
                    opened={bulkModalOpen}
                    onClose={() => {
                      setBulkModalOpen(false);
                    }}
                  />
                )}
              </div>
            </>
          )}
          {canAccessBudget && (
            <>
              <Divider orientation="vertical" />
              <Tooltip
                title={showBudget ? 'Hide budgets' : 'Show budgets'}
                position="bottom"
              >
                <div>
                  <ToggleButton
                    color="secondary"
                    initialState={showBudget}
                    onChange={updateBudgetVisibility}
                    onIcon={<AttachMoney />}
                    offIcon={<MoneyOff />}
                    size={Size.SMALL}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </Stack>
        {isHistorical && <AuthenticatedToolbar />}
        {!isHistorical && (
          <Button
            color="light-blue"
            disabled={!canCreateScenario}
            label="Create scenario"
            startIcon={<AddIcon />}
            onClick={handleOpenAddScenario}
          />
        )}
        <Modal
          open={addScenarioOpen}
          onClose={handleCloseAddScenario}
          maxWidth="calc(40vw)"
          padding="0 32px 48px"
        >
          {addScenarioOpen && (
            <AddScenarioForm onUpdate={handleCloseAddScenario} />
          )}
        </Modal>
      </Stack>
    </FilterProvider>
  );
};

export default SecondaryNavigationBar;
