const Design = (props) => (
  <svg width="1em" height="1em" {...props} viewBox="0 0 39 26" fill="none">
    <path
      d="M16 0C14.8954 0 14 0.89543 14 2V8C14 9.10457 14.8954 10 16 10H19V12.5H8C7.31766 12.5 6.74872 12.6433 6.29434 12.9498C5.83736 13.258 5.54645 13.6975 5.3609 14.1971C5.16878 14.7144 5.07891 15.3314 5.03689 16H2C0.895431 16 0 16.8954 0 18V24C0 25.1046 0.895431 26 2 26H9C10.1046 26 11 25.1046 11 24V18C11 16.8954 10.1046 16 9 16H6.03915C6.07733 15.4255 6.15218 14.9388 6.29834 14.5453C6.43099 14.1881 6.61169 13.9419 6.85355 13.7788C7.09802 13.6139 7.45517 13.5 8 13.5H18.9998L19 16H16C14.8954 16 14 16.8954 14 18V24C14 25.1046 14.8954 26 16 26H23C24.1046 26 25 25.1046 25 24V18C25 16.8954 24.1046 16 23 16H20V13.5H30.5C31.0843 13.5 31.5045 13.5652 31.8148 13.6923C32.1091 13.8127 32.3223 13.9957 32.4885 14.2807C32.6634 14.5803 32.7958 15.0097 32.8811 15.6315C32.8971 15.7486 32.9114 15.8714 32.9239 16H30C28.8954 16 28 16.8954 28 18V24C28 25.1046 28.8954 26 30 26H37C38.1046 26 39 25.1046 39 24V18C39 16.8954 38.1046 16 37 16H33.9282C33.9125 15.8239 33.8938 15.6559 33.8718 15.4956C33.778 14.8121 33.6207 14.2367 33.3523 13.7767C33.0753 13.3021 32.6919 12.9708 32.1936 12.7668C31.7113 12.5693 31.143 12.5 30.5 12.5H20V10H23C24.1046 10 25 9.10457 25 8V2C25 0.895431 24.1046 0 23 0H16Z"
      fill="currentColor"
    />
  </svg>
);

export default Design;
