import * as d3 from 'd3';
import { Fragment, useEffect, useState } from 'react';
import { defaultExpanded } from '@/organisms/charts';
import { filterVisibleChartData } from '@/organisms/charts';
import { ChartRowType } from '@/shared/enums';
import CenteredRow from './CenteredRow';

const ITEM_HEIGHT = 34;
const PADDING = 16;

const CenteredStackChart = ({
  alwaysExpanded,
  height,
  id,
  initialData,
  metric = 'percentage',
  metricTotals,
  onClick,
  onNavigate,
  relativeScale = true,
  width,
}) => {
  const [expandMap, setExpandMap] = useState(defaultExpanded(alwaysExpanded));
  const [focusItem, setFocusItem] = useState();

  const [data, setData] = useState([]);
  const dataHeight = ITEM_HEIGHT * data.length + data.length * PADDING;
  const labelWidth = 100;
  const chartWidth = width - labelWidth * 2;

  // This useEffect handles new data from upstream.
  useEffect(() => {
    if (!initialData) {
      return;
    }

    const expandedMap = alwaysExpanded
      ? defaultExpanded(alwaysExpanded)
      : expandMap;

    const filteredData = filterVisibleChartData(initialData, expandedMap);

    setData(filteredData.data);
    setExpandMap(filteredData.expandMap);
  }, [JSON.stringify(initialData)]);

  // The yScale function is used for calculating x axis co-ordinates.
  const yScale = !height
    ? null
    : d3.scaleBand(
        data.map(({ id }) => {
          return id;
        }),
        [0, dataHeight]
      );

  // The xScale function is used for calculating the bar heights.
  const xScale = !width
    ? null
    : d3
        .scaleLinear()
        .domain([
          0,
          metric === 'percentage' && !relativeScale
            ? 100
            : d3.max(data, (d) => {
                return d.data?.[metric];
              }),
        ])
        .range([0, chartWidth]);

  const handleRowClick = (row) => {
    if (row && row.id !== focusItem) {
      setFocusItem(row.id);
    } else {
      setFocusItem(null);
    }

    onClick?.(row);
  };

  const topMargin =
    height - dataHeight - dataHeight > 0
      ? (height - dataHeight - ITEM_HEIGHT) / 2
      : 16;

  return (
    xScale &&
    yScale && (
      <g className="centered-stack-chart">
        <foreignObject
          onClick={() => handleRowClick()}
          x={0}
          y={0}
          height={height}
          width={width}
        />
        {data.map((row) => (
          <Fragment key={row.id}>
            {row.type !== ChartRowType.TITLE && (
              <CenteredRow
                barHeight={ITEM_HEIGHT}
                chartWidth={chartWidth}
                focused={focusItem}
                labelWidth={labelWidth}
                metric={metric}
                metricTotals={metricTotals}
                onClick={handleRowClick}
                row={row}
                topMargin={topMargin}
                width={width}
                xScale={xScale}
                yScale={yScale}
              />
            )}
          </Fragment>
        ))}
      </g>
    )
  );
};

export default CenteredStackChart;
