const PivotTableIcon = (props) => (
  <svg width="1em" height="1em" {...props} viewBox="0 0 40 27" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.28687C0 1.63001 1.34314 0.286865 3 0.286865H36.6986C38.3555 0.286865 39.6986 1.63001 39.6986 3.28687V23.066C39.6986 24.7229 38.3555 26.066 36.6986 26.066H3C1.34315 26.066 0 24.7229 0 23.066V3.28687ZM3 2.28687C2.44772 2.28687 2 2.73458 2 3.28687V5H37.6986V3.28687C37.6986 2.73458 37.2509 2.28687 36.6986 2.28687H3ZM37.6986 7H33V24.066H36.6986C37.2509 24.066 37.6986 23.6183 37.6986 23.066V7ZM31 7H2V23.066C2 23.6183 2.44772 24.066 3 24.066H31V7Z"
      fill="currentColor"
    />
  </svg>
);

export default PivotTableIcon;
