import * as Colors from '@/atoms/colors';
import { Shadow } from '@/atoms/colors';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';

const OutlineCard = ({
  actionText,
  justifyContent = 'center',
  children,
  headerAction,
  information,
  isTitleAction,
  margin = 2,
  onClick,
  padding = 2,
  title,
}) => {
  return (
    <Grow in={true} timeout={500}>
      <Box
        sx={{
          borderRadius: '4px',
          backgroundColor: Colors.Card.outline.backgroundColor,
          border: `1px solid ${Colors.Card.outline.borderColor}`,
          boxShadow: Shadow.low,
          height: '100%',
          color: Colors.Base.font.primary,
        }}
      >
        <Stack justifyContent={justifyContent} sx={{ height: '100%' }}>
          {(Boolean(title) || Boolean(headerAction)) && (
            <CardHeader
              information={information}
              isTitleAction={isTitleAction}
              title={title}
              action={headerAction}
            />
          )}
          <Box sx={{ px: padding, mt: margin, mb: onClick ? 0 : margin }}>
            {children}
          </Box>
          {Boolean(onClick) && (
            <Box sx={{ px: padding, mt: 3, mb: onClick ? margin : 0 }}>
              <CardFooter actionText={actionText} onClick={onClick} />
            </Box>
          )}
        </Stack>
      </Box>
    </Grow>
  );
};

export default OutlineCard;
