import { EntityType } from '@/shared/enums';

export default function getEntityProps({
  entityType,
  activity,
  role,
  manager,
  person,
  group,
  property,
  propertyValue,
  tag,
}) {
  if (entityType === EntityType.ACTIVITY) {
    return {
      id: activity.library_uuid,
      uuid: activity.uuid,
      props: {
        description: activity.__description,
        library_uuid: activity.library_uuid,
      },
    };
  }

  // We subtract a layer as it allows us to combine managers and roles together
  // in their correct layer.
  if (entityType === EntityType.LAYER) {
    const layer = role?.__layer ?? group?.__layer ?? 1;

    const layerNumber = layer - 1;

    return {
      id: `layer${layerNumber}`,
      props: {
        title: `Layer ${layerNumber}`,
        layerNumber,
      },
    };
  }

  if (entityType === EntityType.MANAGER) {
    return {
      id: manager.title,
      uuid: manager.uuid,
      props: {
        title: manager.title,
      },
    };
  }

  if (entityType === EntityType.ROLE) {
    return {
      id: role.title,
      uuid: role.uuid,
      props: {
        title: role.title,
        isUnallocated: role.isUnallocated ?? false,
        isManager: role.is_manager,
      },
    };
  }
  if (entityType === EntityType.GROUP) {
    return {
      id: group?.name ?? 'UNALLOCATED',
      uuid: group?.uuid ?? 'UNALLOCATED',
      props: {
        name: group?.name ?? 'No team',
      },
    };
  }
  if (entityType === EntityType.PERSON) {
    return {
      id: person.uuid,
      props: {
        name: person.name,
        uuid: person.uuid,
        avatar: person.avatar,
        isUnassigned: person.isUnassigned ?? false,
        role_uuid: role.uuid,
      },
    };
  }
  if (
    entityType === EntityType.PROPERTY ||
    entityType === EntityType.PROPERTY_VALUE
  ) {
    return {
      id: property.uuid,
      props: { ...property },
    };
  }
  if (entityType === EntityType.TAG) {
    return {
      id: tag.uuid,
      props: {
        uuid: tag.uuid,
        description: tag.description,
        name: tag.name,
        color: tag.color,
      },
    };
  }
}
