import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { EntityMetric } from '@/lib/enums';
import HorizontalBar from '../HorizontalBar/HorizontalBar';
import VerticalLabels from '../VerticalLabels/VerticalLabels';

const HorizontalRow = ({
  chartXPosition,
  expanded,
  isHover,
  isPercentageMetric,
  metric,
  metricTotals,
  onNavigate,
  onClick,
  onHover,
  scaledItemHeight,
  row,
  labelWidth,
  width,
  height,
  yScale,
  xScale,
}) => {
  const scaleMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE ? EntityMetric.HOURS : metric;

  const yPosition = yScale(row.id);
  const xPosition = xScale(row.data[scaleMetric]);

  const handleClick = (event) => {
    if (event.detail === 2 && onNavigate) {
      handleNavigate();
      return;
    }
    onClick?.(row);
  };

  const handleNavigate = () => {
    onNavigate(row);
  };

  return (
    <g
      className="horizontal-bar-chart-row"
      key={row.id}
      onClick={handleClick}
      onMouseOver={() => onHover(row, true)}
      onMouseOut={() => onHover(row, false)}
      style={{
        cursor: 'pointer',
      }}
      x={0}
      width={width}
    >
      <rect
        className="row-background"
        x={0}
        y={yPosition}
        width={width}
        height={height}
        fill={'transparent'}
      />
      <HorizontalBar
        barHeight={height + 6}
        isPercentageMetric={isPercentageMetric}
        metric={metric}
        row={row}
        xChartPosition={chartXPosition}
        xPosition={xPosition}
        yPosition={yPosition - 1}
      />
      <VerticalLabels
        row={row}
        labelHeight={height}
        expanded={expanded.get(row.id)}
        metric={metric}
        metricTotals={metricTotals}
        width={labelWidth}
        yPosition={yPosition}
      />
      {isHover && onNavigate && (
        <foreignObject
          height={height}
          width={50}
          x={width - 50}
          y={yPosition + 5}
        >
          <Button
            label="View"
            color="secondary"
            onClick={handleNavigate}
            size={Size.X_SMALL}
          />
        </foreignObject>
      )}
    </g>
  );
};

export default HorizontalRow;
