import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

export const CREATE_EXPORT = /* GraphQL */ `
  mutation Export($input: ExportInput!) {
    export(input: $input) {
      uuid
    }
  }
`;

export default function useCreate(query) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: CREATE_EXPORT,
    invalidateQuery: query,
  });

  /**
   * @param {Object} exportables
   * @param {String} [exportables.design]
   * @param {String[]} [exportables.people]
   */
  function create({ design, people }) {
    enqueueSnackbar("We're processing your export, look out for an email!");

    return mutateAsync({
      design,
      people,
    });
  }

  return create;
}
