import { EntityType } from '@/shared/enums';
import ColorSpectrum from './ColorSpectrum';

export default function stackColor(percentage, entityType = EntityType.ROLE) {
  if (percentage >= 40) {
    return ColorSpectrum[entityType][4];
  }
  if (percentage >= 30) {
    return ColorSpectrum[entityType][3];
  }
  if (percentage >= 20) {
    return ColorSpectrum[entityType][2];
  }
  if (percentage >= 10) {
    return ColorSpectrum[entityType][1];
  }

  return ColorSpectrum[entityType][0];
}
