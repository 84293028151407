import { useState } from 'react';
import { ActivityConfig } from '@/organisms/activities';
import {
  ActivityCardList,
  EntityOverviewCard,
  TagListCard,
} from '@/organisms/cards';
import { RoleActionBar } from '@/organisms/toolbars';
import { StoreType } from '@/shared/enums';
import { useCollaborators } from '@/shared/hooks';
import { useScenario, useComparisonMetrics } from '@/shared/hooks';
import FlowsProvider from '@/shared/providers/FlowsProvider';
import ScenarioProvider, {
  useScenarioContext,
} from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

export const RoleLayout = ({ comparison }) => {
  const scenario = useScenarioContext();
  const { permission } = useCollaborators();
  const [showComparison, setShowComparison] = useState(false);

  // Load the comparison scenario so we can provider it to the scenario
  // provider. This allows us to reduce the number of times we create the
  // scenario but make the same structured data available to the context
  // consumers.
  const comparisonScenario = useScenario({
    ...comparison,
    storeType: StoreType.COMPARISON,
  });

  const { metrics, activityMap } = useComparisonMetrics(
    scenario,
    comparisonScenario
  );

  const hasComparison = comparison && comparisonScenario?.scenario;

  const handleComparisonToggle = (value) => {
    setShowComparison(value);
  };

  return (
    <>
      <Grid
        container
        sx={{
          pb: 12,
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            mb: 4,
            transform: showComparison
              ? 'translate(0, 0)'
              : 'translate(-22.5%, 0)',
            transition: 'transform 300ms',
          }}
        >
          <Stack direction="row">
            <Box width="45%">
              {showComparison && (
                <Fade in={showComparison} style={{ transitionDelay: '300ms' }}>
                  <div>
                    <ScenarioProvider
                      existingScenario={comparisonScenario}
                      designId={comparison?.designId}
                      level={comparison?.level}
                      storeType={StoreType.COMPARISON}
                    >
                      <EntityOverviewCard isEditable={permission?.edit} />
                    </ScenarioProvider>
                  </div>
                </Fade>
              )}
            </Box>
            <Box width="55%" pl={showComparison ? 6 : 0}>
              <EntityOverviewCard isEditable={permission?.edit} />
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mb: 6,
            transform: showComparison
              ? 'translate(0, 0)'
              : 'translate(-22.5%, 0)',
            transition: 'transform 300ms',
          }}
        >
          <Stack direction="row">
            <Box width="45%">
              {showComparison && (
                <Fade in={showComparison} style={{ transitionDelay: '300ms' }}>
                  <div>
                    <ScenarioProvider
                      existingScenario={comparisonScenario}
                      designId={comparison?.designId}
                      level={comparison?.level}
                      storeType={StoreType.COMPARISON}
                    >
                      <ActivityCardList settings={ActivityConfig} />
                    </ScenarioProvider>
                  </div>
                </Fade>
              )}
            </Box>
            <Box width="55%" pl={showComparison ? 6 : 0}>
              <ActivityCardList
                settings={{
                  ...ActivityConfig,
                  isEditable: true,
                  showDragDrop: true,
                }}
                comparisonActivityMap={activityMap}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mb: 6,
            transform: showComparison
              ? 'translate(0, 0)'
              : 'translate(-22.5%, 0)',
            transition: 'transform 300ms',
          }}
        >
          <Stack direction="row">
            <Box width="45%">
              {showComparison && (
                <Fade in={showComparison} style={{ transitionDelay: '300ms' }}>
                  <div>
                    <ScenarioProvider
                      existingScenario={comparisonScenario}
                      designId={comparison?.designId}
                      level={comparison?.level}
                      storeType={StoreType.COMPARISON}
                    >
                      <TagListCard
                        title="Activity tags"
                        direction="row"
                        comparison={comparison}
                        isCondensedView
                      />
                    </ScenarioProvider>
                  </div>
                </Fade>
              )}
            </Box>
            <Box width="55%" pl={showComparison ? 6 : 0}>
              <TagListCard
                title="Activity tags"
                direction="row"
                comparison={comparison}
                isCondensedView
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {hasComparison && (
        <FlowsProvider>
          <RoleActionBar metrics={metrics} onCompare={handleComparisonToggle} />
        </FlowsProvider>
      )}
    </>
  );
};

export default RoleLayout;
