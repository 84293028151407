import { entity as entityColor } from '@pkg/utils/colors';

export default function metricColor(entityType) {
  const color = entityColor(entityType);

  return {
    foreground: {
      normal: color.main,
      hover: color.shades[2],
    },
    background: {
      normal: color.tints[1],
      hover: color.tints[3],
    },
  };
}
