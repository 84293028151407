import { useEffect, useState } from 'react';
import { EntityType } from '@/shared/enums';
import { useScenarioContext, useTagContext } from '@/shared/providers';
import mapActivitiesByTag from '@/shared/utils/mapActivitiesByTag';
import { Tags } from '@pkg/entities';
import TagCategory from '@/components/DashboardContainer/enums/TagCategory';

/**
 * @param {String} key
 * @return {Boolean}
 */
const isCustomTag = (key) => {
  return (
    key !== TagCategory.SYSTEM &&
    key !== TagCategory.PRIMARY &&
    key !== TagCategory.NOT_TAGGED
  );
};

/**
 * @param {Object} tagMap
 * @return {Array}
 */
const sortTagList = (tagMap) => {
  if (!tagMap?.size) {
    return [];
  }

  return Array.from(tagMap.entries())
    .sort(([, tagA], [, tagB]) => tagB.__aggregateHours - tagA.__aggregateHours)
    .map(([, tag]) => tag);
};

/**
 * Sorts the custom tags grouped by their category.
 * @param {Object}
 * @return {Array}
 */
const sortCustomTagList = (tagMap, visibleTagSet) => {
  return Array.from(tagMap.entries())
    .filter(([key]) => isCustomTag(key))
    .sort(([, tagA], [, tagB]) => tagB.__aggregateHours - tagA.__aggregateHours)
    .map(([key, customTag]) => {
      return {
        ...customTag,
        tags: sortTagList(customTag.tags).filter((tag) =>
          visibleTagSet ? visibleTagSet.has(tag.uuid) : true
        ),
        uuid: key,
      };
    });
};

const useTaggedScenarioActivities = ({
  combineTagCategories,
  visibleTagSet,
} = {}) => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const { tagMap } = useTagContext();
  const { ordered: tags } = Tags.useStore();
  const [customTags, setCustomTags] = useState();
  const [primaryTags, setPrimaryTags] = useState();
  const [taggedActivityMap, setTaggedActivityMap] = useState();

  useEffect(() => {
    if (!scenario?.entity || !tags || !tagMap.size) {
      return;
    }

    const taggedActivityMap = mapActivitiesByTag(
      scenario?.relationships?.get(EntityType.ACTIVITY),
      snapshotEntityMap,
      tags
    );

    if (!taggedActivityMap?.category) {
      return;
    }

    setTaggedActivityMap(taggedActivityMap);
  }, [
    scenario?.entity?.uuid,
    scenario?.entity?.__uuid,
    scenario?.entity?.updated_at,
    JSON.stringify(scenario?.entity?.__visible_metrics),
    tags,
    tagMap,
  ]);

  // Create lists from the tagged activity map when it's been updated.
  useEffect(() => {
    if (!taggedActivityMap?.category?.size) {
      return;
    }

    const primaryTags = [
      ...sortTagList(taggedActivityMap.category.get(TagCategory.SYSTEM)?.tags),
      ...sortTagList(taggedActivityMap.category.get(TagCategory.PRIMARY)?.tags),
      ...(taggedActivityMap.category
        .get(TagCategory.NOT_TAGGED)
        ?.tags.values() ?? []),
    ];

    const customTags = sortCustomTagList(
      taggedActivityMap.category,
      visibleTagSet
    );

    if (combineTagCategories) {
      customTags.forEach(({ tags }) => primaryTags.push(...tags));
    }

    setPrimaryTags(
      primaryTags.filter((tag) => {
        return visibleTagSet ? visibleTagSet.has(tag.uuid) : true;
      })
    );

    setCustomTags(customTags.filter(({ tags }) => tags.length > 0));
  }, [taggedActivityMap]);

  return {
    taggedActivityMap,
    primaryTags,
    customTags,
  };
};

export default useTaggedScenarioActivities;
