import { useMemo, useState, useEffect } from 'react';
import { useScenarioActivities } from '@/shared/hooks';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { DesignEntity } from '@/lib/enums';

const useActivityList = ({
  comparisonActivityMap = null,
  coreActivitySet = null,
  config,
  includesTags,
  excludesTags,
  visibleTags = null,
}) => {
  const { scenario } = useScenarioContext();
  const { activities, metrics } = useScenarioActivities({
    sortKey: 'order',
    sortType: 'ASC',
  });

  const [originalActivities, setOriginalActivities] = useState();

  const isRole = scenario?.entity?.__type === DesignEntity.ROLE;

  useEffect(() => {
    if (originalActivities || !comparisonActivityMap?.size) {
      return;
    }

    setOriginalActivities(new Set([...comparisonActivityMap.keys()]));
  }, [comparisonActivityMap]);

  // Memoise the mapped activities to prevent unnecessary re-renders.
  const activityList = useMemo(() => {
    const includesTagSet = new Set(includesTags ?? []);
    const excludesTagSet = new Set(excludesTags ?? []);

    const list = [];

    (activities ?? []).forEach((activity) => {
      if (includesTags) {
        const include = activity.tags.find((tag) => includesTagSet.has(tag));

        if (!include) return;
      }

      if (excludesTags) {
        const exclude = activity.tags.find((tag) => excludesTagSet.has(tag));

        if (exclude) return;
      }

      const isCoreActivity = coreActivitySet?.has(activity.uuid);
      const tags = visibleTags
        ? activity.tags.filter((tag) => visibleTags?.has(tag)) ?? []
        : activity.tags;

      list.push({
        id: activity.uuid,
        uuid: activity.uuid,
        comparisonHours: comparisonActivityMap?.get(activity.uuid)?.hours,
        description: activity.__description,
        disabled: activity?.disabled_at,
        hours: isRole ? activity.hours : activity.__aggregateHours,
        entityType: scenario?.entity?.__type,
        metrics,
        roleId: scenario?.entity?.uuid,
        isCoreActivity,
        isNew: !originalActivities?.has(activity.uuid),
        tags: tags,
        updatedAt: activity.updated_at,
      });
    });

    return list;
  }, [
    JSON.stringify(activities),
    scenario?.entity?.updated_at,
    JSON.stringify(config),
    coreActivitySet,
    originalActivities,
  ]);

  return {
    activityList,
    activityCount: activities?.length,
    metrics,
    entity: {
      uuid: scenario?.entity?.uuid,
      __type: scenario?.entity?.__type,
    },
  };
};

export default useActivityList;
