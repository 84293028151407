import { Base as BaseColor } from '@/atoms/colors';
import { ScopeSelector } from '@/molecules/navigation';
import { EventName, EventProperty } from '@/shared/enums';
import { ViewMode } from '@/shared/enums';
import { useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import { Analytics } from '@pkg/analytics';
import { ucfirst } from '@pkg/utils/strings';
import { core } from '@/lib/theme/tokens/palettes';

const ScopeNavigation = () => {
  const { scope, selectScope, selectViewMode, view } = useViewModeContext();

  const handleSelectScope = (option) => {
    selectScope(option);
    Analytics.track(EventName.SWITCHED_SCOPE, {
      [EventProperty.ENTRY]: 'Scope navigation',
      [EventProperty.SCOPE]: ucfirst(option, true),
    });
  };

  return (
    <>
      {view !== ViewMode.DIAGRAM && (
        <Box
          height={`calc(100vh - 92px)`}
          width={100}
          sx={{
            position: 'absolute',
            top: 92,
            left: 0,
            zIndex: 3,
            backgroundColor: core.white,
            boxShadow: `1px 0 0 0 ${BaseColor.border.primary}`,
          }}
          spacing={1}
          justifyContent="space-between"
          pt={2}
        >
          <ScopeSelector
            initialSelected={scope}
            view={view}
            onClick={handleSelectScope}
          />
        </Box>
      )}
    </>
  );
};

export default ScopeNavigation;
