import { useState, useEffect } from 'react';
import { entityChart } from '@/organisms/charts';
import { useAggregateEntities } from '@/shared/hooks';

const useAggregateChartData = ({
  entityType,
  excludeSeparators,
  excludeUnmanaged,
  includeStack,
  dimension,
  filter,
  sort,
}) => {
  const [chartData, setChartData] = useState();
  const [metricTotals, setMetricTotals] = useState();

  const { entities } = useAggregateEntities({
    entityType,
    excludeUnmanaged,
    order: dimension.order,
    filter,
    sort,
  });

  useEffect(() => {
    if (!entities?.metrics) {
      setChartData([]);
      return;
    }

    const chartData = entityChart({
      excludeSeparators,
      entities,
      includeStack,
      sort,
    });

    setChartData(chartData);
    setMetricTotals(entities.metrics);
  }, [entities?.metrics, JSON.stringify(sort)]);

  return {
    chartData,
    metricTotals,
  };
};

export default useAggregateChartData;
