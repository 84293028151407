import { useEffect, useState } from 'react';
import { metricListFromObject } from '@/organisms/insights';
import { calculateActivityInsightMetrics } from '@/organisms/insights';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';

const useInsightMetrics = ({ metricList, filter, ids }) => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const { showBudget } = useViewModeContext();
  const [metrics, setMetrics] = useState();

  useEffect(() => {
    if (!scenario) {
      return;
    }

    const metrics = calculateActivityInsightMetrics({
      filter,
      scenario,
      snapshotEntityMap,
    });

    setMetrics(metricListFromObject(metrics, metricList, showBudget));
  }, [
    scenario?.entity?.__uuid,
    scenario?.entity?.uuid,
    scenario?.entity?.__visible_metrics,
    showBudget,
    JSON.stringify(ids),
    JSON.stringify(filter),
  ]);

  return metrics;
};

export default useInsightMetrics;
