import { Size } from '@/atoms/enums';
import { TextMetricGroup } from '@/molecules/metrics';
import { useInsightMetrics } from '@/organisms/insights';
import { ScopeType } from '@/shared/enums';
import { useViewModeContext, useInsightsContext } from '@/shared/providers';

const InsightMetrics = () => {
  const { scope, scopeEntity, id } = useViewModeContext();
  const { filter } = useInsightsContext();
  const ids = filter[ScopeType.ACTIVITIES]?.ids;

  const metrics = useInsightMetrics({
    filter,
    ids: ids ? [...ids] : [],
  });

  return (
    metrics && (
      <TextMetricGroup
        alignMetrics="flex-end"
        alignItems="space-between"
        leftPadding={6}
        rightPadding={0.5}
        metrics={metrics}
        removePadding={true}
        showDividers={true}
        size={Size.SMALL}
        spacing={0}
        textAlignment="flex-end"
      />
    )
  );
};

export default InsightMetrics;
