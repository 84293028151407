import { useEffect, useMemo, useState } from 'react';
import { useDatabase } from '@pkg/database';
import { Bool } from '@pkg/utils';
import { ClientError } from '@/lib/enums';
import useFetch from './useFetch';

/**
 * @return {Object}
 */
export default function useRevision(revisionId, isCancelled) {
  const database = useDatabase();
  const fetch = useFetch();

  const [isLoaded, setLoaded] = useState();
  const [revision, setRevision] = useState(null);
  const [error, setError] = useState(null);

  /**
   * Cancel loading based on upstream events
   */
  useEffect(() => {
    if (isCancelled) {
      setError(ClientError.CANCELLED);
      setLoaded(false);
      setRevision(null);
    }
  }, [isCancelled]);

  /**
   * Load revision from store
   */
  const loadRevision = async () => {
    const revision = await database.designRevisions.get(revisionId);
    if (!isCancelled) {
      setLoaded(Boolean(revision));
      setRevision(revision ?? null);
    }
  };

  useEffect(() => {
    if (revisionId && !isCancelled) {
      loadRevision();
    }
  }, [revisionId, isLoaded]);

  /**
   * Load revision from store
   */
  async function fetchRevision() {
    const { error, revision } = await fetch(revisionId);
    if (!isCancelled) {
      setError(error ?? null);
      setLoaded(Boolean(revision));
      setRevision(revision ?? null);
    }
  }

  useEffect(() => {
    if (isLoaded === false && !isCancelled) {
      fetchRevision();
    }
  }, [isLoaded]);

  /**
   * Finalise loading state and result
   */
  return useMemo(() => {
    const isError = Boolean(error);
    const isLoading = !Bool.is(isLoaded) && !isError;
    return { revision, error, isError, isLoading };
  }, [revision, error, isLoaded]);
}
