import { DEFAULT_ACCESS } from '@pkg/access/designs/useAccess';

export const INITIAL_DATA_STATE = Object.freeze({
  access: DEFAULT_ACCESS,
  design: null,
  revision: null,
  snapshot: null,
  difference: null,
  error: null,
  isError: false,
  isHistorical: false,
  isLoading: true,
  isPublic: true,
  isReadOnly: true,
  isReady: false,
  isScenario: false,
});

export default function createDataSlice() {
  return INITIAL_DATA_STATE;
}
