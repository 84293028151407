import { Fragment } from 'react';
import { Divider } from '@/atoms/dividers';
import { TextMetric } from '@/atoms/metrics';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const leftMetricSpacing = (alignMetrics, firstIndex, showDividers) => {
  switch (true) {
    case alignMetrics === 'flex-end' && !firstIndex:
      return 2;
    case alignMetrics === 'flex-start' && !firstIndex:
      return 1;
    default:
      return 0.5;
  }
};

const rightMetricSpacing = (alignMetrics, lastIndex, showDividers) => {
  switch (true) {
    case alignMetrics === 'flex-start' && !lastIndex:
      return 2;
    case alignMetrics === 'flex-end' && !lastIndex:
      return showDividers ? 1 : 0;
    default:
      return 0.5;
  }
};

const TextMetricGroup = ({
  alignMetrics = 'center',
  boldText,
  dividerColor,
  endDivider,
  showDividers = true,
  metrics,
  metricDirection,
  removePadding,
  size,
}) => {
  const isCenterAligned = alignMetrics === 'center';

  return (
    <Stack
      direction="row"
      justifyContent={isCenterAligned ? 'space-between' : alignMetrics}
      spacing={isCenterAligned ? 1 : 0}
      alignItems="top"
    >
      {metrics.map((metric, index) => (
        <Fragment key={`${metric.label}-${metric.value}`}>
          <Box
            pl={leftMetricSpacing(alignMetrics, index === 0, showDividers)}
            pr={rightMetricSpacing(
              alignMetrics,
              index === metrics.length - 1,
              showDividers
            )}
          >
            <TextMetric
              alignItems={alignMetrics}
              boldText={boldText}
              direction={metricDirection}
              formatOptions={metric.formatOptions}
              label={metric.label}
              removePadding={removePadding}
              size={size}
              value={metric.value}
            />
          </Box>
          {showDividers && (index < metrics.length - 1 || endDivider) && (
            <Divider color={dividerColor} orientation="vertical" />
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

export default TextMetricGroup;
