import { create } from 'zustand';

const INITIAL_STATE = {
  design: null,
  snapshot: null,
  snapshotEntityMap: null,
  isLoading: true,
};

const useComparisonDesignStore = create((set) => ({
  reset: (property = '*') => {
    console.debug(`ComparisonDesignStore.reset.${property}`);
    if (property !== '*') {
      return set({ [property]: INITIAL_STATE[property] });
    }
    return set(INITIAL_STATE);
  },
  set: (state) => {
    console.debug('ComparisonDesignStore.set', state);
    return set(state);
  },
}));

export default useComparisonDesignStore;
