import * as d3 from 'd3';
import { Fragment, useEffect, useState } from 'react';
import { defaultExpanded } from '@/organisms/charts';
import { filterVisibleChartData } from '@/organisms/charts';
import { isPercentageMetric } from '@/organisms/charts';
import { ChartRowType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';
import VerticalRow from './VerticalRow';

const ITEM_WIDTH = 56;
const PADDING = 8;

const VerticalStackChart = ({
  alwaysExpanded,
  fullStackChart = true,
  height,
  id,
  initialData,
  labelHeightPercentage = 0.35,
  metric = 'percentage',
  metricTotals,
  onNavigate,
  relativeScale = true,
  title = 'Activity description',
  width,
}) => {
  const [expandMap, setExpandMap] = useState(defaultExpanded(alwaysExpanded));

  const [data, setData] = useState([]);
  const dataWidth = data.length * ITEM_WIDTH;
  const labelHeight = Math.abs(height * labelHeightPercentage);
  const chartHeight = height - labelHeight - PADDING * 2;
  const [maxBarValue, setMaxBarValue] = useState(0);
  const percentageMetric = isPercentageMetric(metric);
  const scaleMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE
      ? EntityMetric.PERCENTAGE
      : metric;

  // The row click handles the expand and collapse functions of the chart.
  const handleRowClick = (row) => {
    const updatedMap = new Map(expandMap);
    if (row.expanded) {
      // To collapse the selected row and all of it's children we do a partial
      // string match on the id and remove all entries.
      Array.from(expandMap.entries()).forEach(([expandedId]) => {
        if (expandedId.includes(row.id)) {
          updatedMap.delete(expandedId);
        }
      });
    } else {
      updatedMap.set(row.id, 0);
    }

    const filtered = filterVisibleChartData(initialData, updatedMap);

    setExpandMap(filtered.expandMap);
    setData(filtered.data);
  };

  // This useEffect handles new data from upstream.
  useEffect(() => {
    if (!initialData) {
      return;
    }

    const expandedMap = alwaysExpanded
      ? defaultExpanded(alwaysExpanded)
      : expandMap;

    const filteredData = filterVisibleChartData(initialData, expandedMap);

    const maxBarValue =
      scaleMetric === EntityMetric.PERCENTAGE && !relativeScale
        ? 100
        : d3.max(filteredData.data, (d) => d.data?.[scaleMetric]);

    setMaxBarValue(maxBarValue);
    setData(filteredData.data);
    setExpandMap(filteredData.expandMap);
  }, [JSON.stringify(initialData)]);

  // The xScale function is used for calculating x axis co-ordinates.
  const xScale = !width
    ? null
    : d3.scaleBand(
        data.map(({ id }) => {
          return id;
        }),
        [0, dataWidth]
      );

  // The yScale function is used for calculating the bar heights.
  const yScale = !height
    ? null
    : d3
        .scaleLinear()
        .domain([0, maxBarValue])
        .range([0, chartHeight - 32]);

  useEffect(() => {
    if (!width || !height) {
      return;
    }

    const paddedWidth = dataWidth + PADDING;

    const updatedWidth = width > paddedWidth ? width : paddedWidth + ITEM_WIDTH;

    // Update the height of the svg so we can scroll the list of chart bars
    // vertically.
    d3.select(`#${id}`)
      .attr('width', updatedWidth)
      .attr('viewBox', `0 0 ${updatedWidth} ${height}`);
  }, [width, height, JSON.stringify(data), metric]);

  return (
    xScale &&
    yScale && (
      <g className="vertical-stack-chart">
        {data.map((row, index) => (
          <Fragment key={row.id}>
            {row.type !== ChartRowType.TITLE && (
              <VerticalRow
                chartHeight={chartHeight}
                expanded={expandMap}
                fullStackChart={fullStackChart}
                isPercentageMetric={percentageMetric}
                labelHeight={labelHeight}
                maxBarValue={maxBarValue}
                metric={metric}
                metricTotals={metricTotals}
                onClick={row.hasChildren ? handleRowClick : null}
                row={row}
                width={ITEM_WIDTH}
                xScale={xScale}
                yScale={yScale}
              />
            )}
          </Fragment>
        ))}
      </g>
    )
  );
};

export default VerticalStackChart;
