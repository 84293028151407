import { DesignEntity, Visibility } from '@/lib/enums';

export default function formatByRole({
  entityId,
  entityType,
  shouldIncludeNested,
  snapshotEntityMap,
}) {
  const groupMap = new Map();
  const roleMap = new Map();
  const activityMap = new Map();
  const personMap = new Map();

  Array.from(snapshotEntityMap.get(DesignEntity.ROLE).values()).forEach(
    (role) => {
      // filtered roles
      if (role.__visibility === Visibility.NONE) {
        return;
      }

      const person = role
        ? snapshotEntityMap.get(DesignEntity.PERSON).get(role?.user_uuid)
        : null;

      const group = snapshotEntityMap
        .get(DesignEntity.GROUP)
        .get(role.group_uuid);

      if (role.group_uuid === entityId) {
        if (!roleMap.has(role.uuid)) {
          roleMap.set(role.uuid, role);
        }

        if (person && !personMap.has(person.uuid)) {
          personMap.set(person.uuid, person);
        }

        if (group && entityType === DesignEntity.GROUP) {
          if (!groupMap.has(group.uuid)) {
            groupMap.set(group.uuid, group);
          }
        }
      }

      // If we're not including nested entities we should exit at this point.
      if (!shouldIncludeNested) {
        return;
      }

      // We're here because we want to include nested roles and this checks if
      // the current role is included in the hierarchy of the scenario entity.
      if (role.__path?.has(entityId)) {
        if (role && !roleMap.has(role.uuid)) {
          roleMap.set(role.uuid, role);
        }
        if (person && !personMap.has(person.uuid)) {
          personMap.set(person.uuid, person);
        }
      }
    }
  );

  return {
    activityMap,
    groupMap,
    roleMap,
    personMap,
  };
}
