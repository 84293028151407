import { useEffect, useState } from 'react';
import { PercentageChangeText } from '@/atoms/metrics';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { percent } from '@pkg/utils/numbers';
import { format } from '@pkg/utils/numbers';
import { brand, core } from '@/lib/theme/tokens/palettes';

const scaledBarHeights = (values) => {
  const maxValue = Math.max(...values);

  return values.map((value) => {
    return percent(value, maxValue);
  });
};

const BarChart = ({
  colors,
  values,
  label,
  percentage,
  raw,
  height = 'auto',
}) => {
  const [barHeights, setBarHeights] = useState();
  const [formattedChangeValue, setFormattedChangeValue] = useState();

  useEffect(() => {
    if (values.length) {
      setBarHeights(scaledBarHeights(values));
      setFormattedChangeValue(
        format(raw, {
          signDisplay: 'exceptZero',
        })
      );
    }
  }, [JSON.stringify(values)]);

  return (
    barHeights &&
    values && (
      <Stack
        direction="row"
        spacing={0.5}
        sx={{ height: height }}
        alignItems="end"
      >
        {values.map((item, index) => (
          <Box
            key={`${item.label}-${index}`}
            sx={{
              borderRadius: '2px',
              backgroundColor: colors ? colors[index] : core.white,
              height: `${barHeights[index]}%`,
              width: '6px',
              transition: 'height 300ms',
            }}
          />
        ))}
        <Stack sx={{ pl: 0.5 }}>
          <PercentageChangeText value={percentage} color={brand.yellow.main} />
          <Heading variant="h6" overrideStyles={{ lineHeight: '0.9rem' }}>
            {`${formattedChangeValue} ${label}`}
          </Heading>
        </Stack>
      </Stack>
    )
  );
};

export default BarChart;
