import { Size } from '@/atoms/enums';
import { MetricProperty } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { RowColors } from '@/organisms/charts';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

const getMetricValue = ({ isPercentageMetric, metric, row, scaleMetric }) => {
  if (isPercentageMetric) {
    const metricData = row.data[metric];
    return metricData > 0 ? metricData / 100 : 0;
  }

  return row.data[scaleMetric];
};

const HorizontalBar = ({
  barHeight,
  isPercentageMetric,
  row,
  metric,
  xChartPosition,
  xPosition,
  yPosition,
}) => {
  const width = xPosition - 50 > 4 ? xPosition - 50 : 4;
  const scaleMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE ? EntityMetric.HOURS : metric;

  const value = getMetricValue({
    isPercentageMetric,
    metric,
    row,
    scaleMetric,
  });

  const formatOptions = MetricProperty[metric]?.formatOptions;
  const displayValue = formatOptions
    ? format(value, {
        ...formatOptions,
        notation: 'compact',
      })
    : row.data[metric];

  const colors = RowColors[row.type].bar;

  return (
    <g className="horizontal-bar">
      <rect
        width={width}
        height={barHeight - 18}
        rx={4}
        ry={4}
        x={xChartPosition}
        y={yPosition + 10}
        fill={colors.borderColor}
      />
      <rect
        width={width}
        height={barHeight - 16}
        rx={3}
        ry={3}
        x={xChartPosition}
        y={yPosition + 6}
        fill={colors.backgroundColor}
      />
      <foreignObject
        x={xChartPosition + width + 8}
        y={yPosition}
        width={60}
        height={barHeight}
      >
        <Stack direction="row" alignItems="center" height={barHeight}>
          <Paragraph
            size={Size.SMALL}
            overrideStyles={{
              mb: 0,
              fontWeight: 600,
            }}
          >
            {displayValue}
          </Paragraph>
        </Stack>
      </foreignObject>
    </g>
  );
};

export default HorizontalBar;
