import { useRef } from 'react';
import { CenteredStackChart, D3Chart, EntitiesTable } from '@/organisms/charts';
import { useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const LayersDistributionChart = ({
  chartData,
  fullscreen,
  onItemFocus,
  metric,
  metrics,
  metricList,
  metricTotals,
  sort,
  tableData,
}) => {
  const ref = useRef();
  const { showBudget } = useViewModeContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={fullscreen ? 8 : 7}>
        <Box ref={ref} height={fullscreen ? 500 : 400}>
          <D3Chart
            height={fullscreen ? 500 : 400}
            hideBorder
            id="layer-insights"
            noScrollX
          >
            <CenteredStackChart
              onClick={onItemFocus}
              initialData={chartData}
              metric={metric}
              metricTotals={metricTotals}
              sort={sort}
            />
          </D3Chart>
        </Box>
      </Grid>
      <Grid item xs={fullscreen ? 4 : 5}>
        <EntitiesTable
          chartRef={ref}
          initialData={tableData}
          metrics={metrics}
          metric={metric}
          metricList={metricList}
          showBudget={showBudget}
        />
      </Grid>
    </Grid>
  );
};

export default LayersDistributionChart;
