import { useEffect } from 'react';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import useComparisonDesignStore from './useComparisonDesignStore';

const useComparisonDesignSetup = (designId) => {
  const { designs } = useDesignsContext();

  const set = useComparisonDesignStore((state) => state.set);
  const design = useComparisonDesignStore((state) => state.design);
  const snapshot = useComparisonDesignStore((state) => state.snapshot);
  const snapshotEntityMap = useComparisonDesignStore(
    (state) => state.snapshotEntityMap
  );

  useEffect(() => {
    if (!designId || (design && designId === design.uuid)) {
      return;
    }

    if (!designs?.size) {
      return;
    }

    let hydratedId = designId;

    if (designId === 'main') {
      designs.forEach(({ is_scenario, uuid }) => {
        if (!is_scenario) {
          hydratedId = uuid;
        }
      });
    }

    const hydrated = designs.get(hydratedId);

    set({ design: hydrated.design });
    set({ snapshot: hydrated.snapshot });
    set({ snapshotEntityMap: hydrated.snapshotEntityMap });

    // Remove the comparison store when we no longer need it so updates
    // are loaded the next time. This avoids us constantly monitoring live
    // changes whilst a user is editing their scenario.
    return () => {
      set({ design: null });
      set({ snapshot: null });
      set({ snapshotEntityMap: null });
    };
  }, [designId, designs]);

  return {
    design,
    snapshot,
    snapshotEntityMap,
  };
};

export default useComparisonDesignSetup;
