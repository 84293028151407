import { Size } from '@/atoms/enums';
import { Heading, Label } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import TextMetricSize from './utils/TextMetricSize';

const TextMetric = ({
  alignItems = 'flex-start',
  boldText,
  direction = 'column',
  formatOptions,
  label,
  size = Size.MEDIUM,
  value,
}) => {
  const sizeProps = TextMetricSize[size];
  const formattedValue = format(value, {
    ...formatOptions,
    notation: 'compact',
  });

  return (
    <Stack
      direction={direction}
      alignItems={alignItems}
      justifyContent={direction === 'column' ? 'center' : 'flex-end'}
      spacing={direction === 'column' ? 0 : 0.5}
    >
      {direction === 'column' && (
        <>
          <Heading
            overrideStyles={{
              ...sizeProps.value,
              fontWeight: boldText ? 700 : sizeProps.value.fontWeight,
            }}
            variant="h3"
          >
            {formattedValue}
          </Heading>
          <Heading
            overrideStyles={{
              ...sizeProps.label,
              textTransform: 'uppercase',
            }}
            variant="h4"
          >
            {label}
          </Heading>
        </>
      )}
      {direction === 'row' && (
        <>
          <Label size={size} overrideStyles={{ fontWeight: 500 }}>
            {formattedValue}
          </Label>
          <Label
            size={size}
            overrideStyles={{ textTransform: 'uppercase', fontWeight: 500 }}
          >
            {label}
          </Label>
        </>
      )}
    </Stack>
  );
};

export default TextMetric;
