import { useState } from 'react';
import { NumberBadge } from '@/atoms/badges';
import { Button } from '@/atoms/buttons';
import { Base as BaseColor } from '@/atoms/colors';
import { Popover } from '@/molecules/modals';
import { EventName, EventProperty } from '@/shared/enums';
import {
  SkillProvider,
  TagProvider,
  useFilterContext,
} from '@/shared/providers';
import FilterListIcon from '@mui/icons-material/FilterListRounded';
import Box from '@mui/material/Box';
import { useDebounce } from '@pkg/hooks';
import { Position } from '@/lib/enums';
import FilterGroup from './FilterGroup';

const getFilterCount = (filter) => {
  let filterCount = 0;

  filter.groups?.forEach((group) => {
    const conditions = group.conditions?.filter((condition) => {
      const value = condition?.get('value');
      return value !== null && typeof value !== 'undefined';
    });

    filterCount += conditions?.length;
  });

  return filterCount;
};

const DefaultFilter = {
  comparator: 'AND',
  groups: [
    {
      comparator: 'AND',
      conditions: [],
    },
  ],
};

const Filters = () => {
  const [filter, setFilter] = useState(DefaultFilter);
  const { filter: applyFilter } = useFilterContext();
  const filterCount = getFilterCount(filter);

  // Debounce requires an empty function should an onChange event not be
  // provided.
  const debounceChange = useDebounce(applyFilter ?? (() => {}), 500);

  // This is a placeholder for now, later we will handle groups vs conditions.
  const handleChange = (groupIndex, conditions) => {
    const updatedFilter = { ...filter };

    const updatedGroup = updatedFilter?.groups[groupIndex];

    if (!updatedGroup) {
      return;
    }
    updatedGroup.conditions = conditions;
    updatedFilter.groups[groupIndex] = updatedGroup;

    setFilter(updatedFilter);
    debounceChange(updatedFilter);
  };

  return (
    <TagProvider>
      <SkillProvider>
        <Popover
          position={Position.BOTTOM}
          Component={
            <Button
              label="Filters"
              color="light-blue"
              startIcon={<FilterListIcon />}
              track={{
                name: EventName.OPENED_FILTERS,
                properties: {
                  [EventProperty.ENTRY]: 'Secondary navigation',
                },
              }}
              endIcon={
                filterCount > 0 ? <NumberBadge number={filterCount} /> : null
              }
            />
          }
        >
          <Box
            py={1}
            px={2}
            minWidth="calc(50vw)"
            sx={{ color: BaseColor.font?.primary }}
          >
            {filter.groups.map((group, groupIndex) => (
              <FilterGroup
                groupIndex={groupIndex}
                key={`group-${groupIndex}`}
                onChange={handleChange}
                initialConditions={group.conditions}
              />
            ))}
          </Box>
        </Popover>
      </SkillProvider>
    </TagProvider>
  );
};

export default Filters;
