import { cloneElement, useCallback } from 'react';
import { DragDrop } from '@/layouts';
import { AddFieldButton } from '@/molecules/formElements';
import { ActivityHeading } from '@/molecules/headings';
import {
  ActivityConfig,
  calculateAddButtonPadding,
  useActivityList,
} from '@/organisms/activities';
import { ActivityCard } from '@/organisms/cards';
import { useDefaultActivity } from '@/shared/hooks';
import { DispatchProvider, TagProvider } from '@/shared/providers';
import Box from '@mui/material/Box';

const ActivityCardList = ({
  allowedTagSet,
  comparisonActivityMap,
  coreActivitySet,
  excludesTags,
  includesTags,
  settings,
  TitleComponent = <ActivityHeading />,
  visibleTags,
}) => {
  const { canAddNew, isEditable, showDragDrop, showTotalActivityHours } = {
    ...ActivityConfig,
    ...settings,
  };

  const { activityCount, activityList, entity, metrics } = useActivityList({
    comparisonActivityMap,
    coreActivitySet,
    config: settings,
    excludesTags,
    includesTags,
    visibleTags,
  });

  const defaultActivity = useDefaultActivity();

  const canAddActivity = isEditable && canAddNew;

  const buttonPadding = calculateAddButtonPadding({
    ...ActivityConfig,
    ...settings,
  });

  // Generates the properties to be provided to the dispatch handler when
  // updating the drag and drop order.
  const moveDispatchProps = useCallback(
    (id, order) => ({
      ids: [id],
      owner_uuid: entity?.uuid,
      owner_type: entity?.__type,
      order: order,
    }),
    [entity?.uuid, entity?.__type]
  );

  return (
    <>
      {activityList.length > 0 &&
        Boolean(TitleComponent) &&
        cloneElement(TitleComponent, {
          totalHours: metrics?.totalHours,
          showHours: showTotalActivityHours,
          showDragDrop,
        })}
      <div
        style={{
          marginLeft: includesTags?.length || excludesTags?.length ? '16px' : 0,
        }}
      >
        {activityList.length > 0 && (
          <TagProvider>
            {isEditable && showDragDrop ? (
              <DispatchProvider
                action="activities.move"
                uuid={entity?.uuid}
                isEditable={isEditable}
              >
                <DragDrop
                  component={
                    <ActivityCard
                      allowedTagSet={allowedTagSet}
                      settings={settings}
                    />
                  }
                  createDispatchProperties={moveDispatchProps}
                  items={activityList}
                />
              </DispatchProvider>
            ) : (
              <>
                {activityList.map((activity) => (
                  <Box sx={{ mb: 1 }} key={activity.uuid}>
                    <ActivityCard
                      key={activity.uuid}
                      allowedTagSet={allowedTagSet}
                      id={activity.id}
                      uuid={activity.uuid}
                      comparisonHours={activity.comparisonHours}
                      description={activity.description}
                      disabled={activity.disabled_at}
                      hours={activity.hours}
                      entityType={activity.entityType}
                      isCoreActivity={activity.isCoreActivity}
                      isNew={activity.isNew}
                      settings={settings}
                      metrics={activity.metrics}
                      roleId={activity.roleId}
                      tags={activity.tags}
                      updatedAt={activity.updated_at}
                    />
                  </Box>
                ))}
              </>
            )}
          </TagProvider>
        )}
        {defaultActivity && entity?.uuid && canAddActivity && (
          <Box
            sx={{
              ml: showDragDrop ? 4 : 0,
              pt: 1,
              pr: `${buttonPadding}px`,
            }}
          >
            <DispatchProvider
              action="activities.add"
              uuid={entity?.uuid}
              isEditable={isEditable}
            >
              <AddFieldButton
                label="Add activity"
                entity={entity}
                fields={[
                  {
                    library_uuid: defaultActivity.uuid,
                    owner_uuid: entity.uuid,
                    owner_type: entity.__type,
                    order: activityCount + 1,
                  },
                ]}
              />
            </DispatchProvider>
          </Box>
        )}
      </div>
    </>
  );
};

export default ActivityCardList;
