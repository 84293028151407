import { useEffect, useState } from 'react';
import { StoreType } from '@/shared/enums';
import { useScenario } from '@/shared/hooks';
import { useTaggedScenarioActivities } from '@/shared/hooks';

const useComparisonActivitiesByTag = ({ designId, level } = {}) => {
  const { scenario, snapshotEntityMap } = useScenario({
    designId,
    level,
    storeType: StoreType.COMPARISON,
  });
  const { primaryTags, customTags, taggedActivityMap } =
    useTaggedScenarioActivities({ scenario, snapshotEntityMap });

  const [comparisonTags, setComparisonTags] = useState();

  useEffect(() => {
    if (!primaryTags && !customTags) {
      return;
    }

    const tagMap = new Map();

    const tags = [...primaryTags, ...customTags];

    tags.forEach((tag) => {
      tagMap.set(tag.uuid, tag);
    });

    setComparisonTags(tagMap);
  }, [
    taggedActivityMap,
    JSON.stringify(primaryTags),
    JSON.stringify(customTags),
  ]);

  return {
    tags: comparisonTags,
    totalHours: taggedActivityMap?.__totalHours,
  };
};

export default useComparisonActivitiesByTag;
