import { createContext, useContext, useMemo, useState } from 'react';
import { useScenario } from '@/shared/hooks';

export const ScenarioContext = createContext({
  access: null,
  canPublish: null,
  publish: null,
  scenario: null,
  selectScenario: null,
  snapshotEntityMap: null,
});

export const useScenarioContext = () => useContext(ScenarioContext);

const ScenarioProvider = ({
  children,
  designId,
  existingScenario,
  level,
  storeType,
}) => {
  const [includeNested, setIncludeNested] = useState(true);

  const {
    access,
    canPublish,
    create,
    publish,
    scenario,
    selectScenario,
    snapshotEntityMap,
  } = useScenario({
    designId,
    existingScenario,
    level,
    storeType,
    includeNested,
  });

  const contextState = useMemo(() => {
    return {
      access,
      canPublish,
      create,
      publish,
      scenario,
      selectScenario,
      snapshotEntityMap,
      includeNested,
      setIncludeNested,
    };
  }, [access, canPublish, includeNested, scenario, snapshotEntityMap]);

  return (
    <ScenarioContext.Provider value={contextState}>
      {children}
    </ScenarioContext.Provider>
  );
};

export default ScenarioProvider;
