const ChartRowType = Object.freeze({
  ACTIVITY: 'ACTIVITY',
  GROUP: 'GROUP',
  LAYER: 'LAYER',
  MANAGER: 'MANAGER',
  PERSON: 'PERSON',
  PROPERTY: 'PROPERTY',
  PROPERTY_VALUE: 'PROPERTY_VALUE',
  ROLE: 'ROLE',
  SEPARATOR: 'SEPARATOR',
  TAG: 'TAG',
  TITLE: 'TITLE',
});

export default ChartRowType;
